import React, {lazy, Suspense, useCallback, useEffect, useState} from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import Loading from "../loading/loading";
import {Switch} from "react-router";
import {AppContext} from "./context";

const NewsPage = lazy(() => import('../../pages/news'));
const HomePage = lazy(() => import('../../pages/home'));
const ArticlePage = lazy(() => import('../../pages/article'));
const NotFoundPage = lazy(() => import('../../pages/not-found'));
const DynamicPage = lazy(() => import('../../pages/dynamic-page'));

export default function App() {
    const [articles, setArticles] = useState(null);
    const [analyticsEnabled, setAnalyticsEnabled] = useState(false);

    const initAnalytics = useCallback(() => {
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', process.env.REACT_APP_GA_ID);
    }, [])
    useEffect(() => {
        if(!analyticsEnabled) {return}
        initAnalytics();
    }, [analyticsEnabled, initAnalytics])

    useEffect(() => {
      const siteWrapper = document.querySelector('.site-wrapper');
      const reactRoot = document.querySelector('#root');
     if(window.location.pathname.includes('/news')) {
         siteWrapper.style.display = "none"
         reactRoot.style.display = "block";
     } else {
         siteWrapper.style.display = "block";
         reactRoot.style.display = "none";
     }
    }, []);

    const context = {articles, setArticles, setAnalyticsEnabled};
    return (
        <AppContext.Provider value={context}>
            <div className="app">
                <Router>
                    <Suspense fallback={<Loading/>}>
                        <Switch>
                            <Route path="/" exact={true} component={HomePage}/>
                            <Route path="/news" exact={true} component={NewsPage}/>
                            <Route path="/news/:slug" exact={true} component={ArticlePage}/>
                            <Route path="/_/:slug" component={DynamicPage}/>
                            <Route component={NotFoundPage}/>
                        </Switch>
                    </Suspense>
                </Router>
            </div>
        </AppContext.Provider>
    )
}
